import React, {
  SetStateAction,
  Dispatch,
  ReactNode,
  useState,
  createContext,
} from 'react';

interface instalmentType {
  hasData: boolean;
}

type InstalmentContextType = {
  instalment?: instalmentType;
  setInstalment: Dispatch<SetStateAction<instalmentType | undefined>>
}

type InstalmentContextProviderProps = {
  children: ReactNode
}

export const InstalmentContext = createContext({} as InstalmentContextType);

export const InstalmentContextProvider = ({ children }: InstalmentContextProviderProps) => {
  const [instalment, setInstalment] = useState<instalmentType | undefined>();
  return (
    <InstalmentContext.Provider value={{ instalment, setInstalment }}>
      {children}
    </InstalmentContext.Provider>
  );
};
