import {
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import { isEqual } from '@ngard/tiny-isequal';

import { usePaymentFields } from 'src/hooks/usePaymentFields';
import { useCardData } from 'src/hooks/useCardData';
import { INSTALMENT_WITHOUT_VALUE_HASH } from 'src/components/instalments/PlanInput';
import { InstalmentValueType } from './useInstalments';
import { useVisibleValues } from './useVisibleValues';
import { useValidateFields, Errors } from './useValidateFields';

export enum CardFormFields {
  CARDHOLDER_NAME = 'cardholder_name',
  NUMBER = 'number',
  EXP_MONTH = 'exp_month',
  EXP_YEAR = 'exp_year',
  CSC = 'csc',
  SAVE_CARD = 'save_card',
  INSTALMENT_PLAN = 'instalment_plan',
  INSTALMENT_AGREE_TO_TOC = 'instalment_agree_to_toc',
}

export interface Values {
  cardholder_name?: string;
  // eslint-disable-next-line id-denylist
  number?: string;
  exp_month?: string;
  exp_year?: string;
  csc?: string;
  save_card?: boolean;
  instalment_plan?: string;
  instalment_agree_to_toc?: boolean;
}

type FieldName = keyof Values;
export type FieldValue = string | boolean | undefined | InstalmentValueType;
type ReducerType<T> = React.Reducer<T, Partial<T>>;

interface Props {
  newCardMode: boolean;
  addonsEnabled: boolean;
}

interface Response {
  values: Values;
  errors: Errors;
  isBusy: boolean;
  isValid: boolean;
  handleChange(name: keyof Values, val: FieldValue): void;
  handleSubmit(): void | Promise<void> | unknown;
  forceValidation(): void;
  stopValidation(): void;
}

interface State {
  isSubmitted?: boolean;
  hasSubmitEvent?: boolean;
  isBusy?: boolean;
  dirtyFields?: FieldName[];
  values?: Values;
}

const initialFieldsState = {
  cardholder_name: '',
  // eslint-disable-next-line id-denylist
  number: '',
  exp_month: '',
  exp_year: '',
  csc: '',
  instalment_plan: '',
  instalment_agree_to_toc: false,
  save_card: false,
};

const initialState: State = {
  isSubmitted: false,
  isBusy: false,
  dirtyFields: [],
  values: initialFieldsState,
};

const reducer = (state: State, data: Partial<State>) => ({ ...state, ...data });

export const useCardForm = ({
  newCardMode,
  addonsEnabled,
}: Props): Response => {
  const {
    rememberCard,
  } = usePaymentFields({ newCardMode });
  const { submitData } = useCardData({ newCardMode });
  const [state, setState] = useReducer<ReducerType<State>>(reducer, initialState);

  useEffect(() => {
    // eslint-disable-next-line
    if (!isEqual(initialFieldsState, state.values)) {
      setState({ values: initialFieldsState });
    }
    // eslint-disable-next-line
  }, [newCardMode]);

  const visibleValues = useVisibleValues(state.values || {});

  const {
    errors,
    isValidForm,
  } = useValidateFields({
    values: state.values || {},
    dirtyFields: state.dirtyFields || [],
    visibleValues,
    newCardMode,
    addonsEnabled,
    isSubmitted: !!state.isSubmitted,
  });

  const submissionData = useMemo(() => {
    const hasInstalment = state.values?.instalment_plan !== INSTALMENT_WITHOUT_VALUE_HASH;
    return {
      ...state.values,
      save_card: rememberCard ? true : !!state.values?.save_card,
      instalment_plan: hasInstalment ? state.values?.instalment_plan : undefined,
      instalment_agree_to_toc: hasInstalment ? state.values?.instalment_agree_to_toc : undefined,
    };
  }, [
    state.values,
    rememberCard,
  ]);

  const getDependantFields = (name: keyof Values, val: FieldValue) => {
    if (name === CardFormFields.NUMBER) {
      return {
        [name]: String(val),
        [CardFormFields.INSTALMENT_PLAN]: '',
        [CardFormFields.INSTALMENT_AGREE_TO_TOC]: false,
      };
    }

    return {
      [name]: val,
    };
  };

  const handleChange = (name: keyof Values, val: FieldValue) => {
    const newState: State = {};

    if (!state.dirtyFields?.includes(name)) {
      newState.dirtyFields = [...(state.dirtyFields || []), name];
    }

    if (val !== (state.values || {})[name]) {
      newState.values = {
        ...state.values,
        ...getDependantFields(name, val),
      };
    }

    if (Object.keys(newState).length) {
      setState(newState);
    }
  };

  useEffect(() => {
    if (state.hasSubmitEvent && state.isSubmitted && !state.isBusy) {
      if (isValidForm) {
        setState({ isBusy: true });
        submitData(submissionData);
      } else {
        setState({ hasSubmitEvent: false });
      }
    }
    // eslint-disable-next-line
  }, [
    state.isBusy,
    state.hasSubmitEvent,
    isValidForm,
  ]);

  const handleSubmit = () => {
    setState({
      isSubmitted: true,
      hasSubmitEvent: true,
    });
  };

  return {
    values: visibleValues,
    errors,
    isBusy: !!state.isBusy,
    handleChange,
    handleSubmit,
    forceValidation: () => setState({ isSubmitted: true }),
    stopValidation: () => setState({ isSubmitted: false }),
    isValid: isValidForm,
  };
};
