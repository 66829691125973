/* eslint-disable max-len */
import React from 'react';

export const Right: React.FC = () => (
  <svg width="17" height="17" viewBox="0 0 17 17"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.3335 3.8335L13.0002 8.50016L8.3335 13.1668" stroke="white"
      strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Down: React.FC = () => (
  <svg width="41" height="40" viewBox="0 0 41 40"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 15L20.5 25L30.5 15" stroke="black"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const LeftArrow: React.FC = () => (
  <svg width="21" height="21" viewBox="0 0 21 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4584 10.5H4.79175" stroke="#3A9CF2" strokeWidth="2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.6251 16.3332L4.79175 10.4998L10.6251 4.6665" stroke="#3A9CF2" strokeWidth="2"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Cancelled: React.FC = () => (
  <svg width="100" height="101" viewBox="0 0 100 101"
    fill="none" xmlns="http://www.w3.org/2000/svg" className="info-icon">
    <path d="M50.4167 92.2821C73.4285 92.2821 92.0833 73.6273 92.0833 50.6154C92.0833 27.6035 73.4285 8.94872 50.4167 8.94872C27.4048 8.94872 8.75 27.6035 8.75 50.6154C8.75 73.6273 27.4048 92.2821 50.4167 92.2821Z" stroke="#4996D1"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M50.5 56.6154L50.5 26.6154" stroke="#4996D1"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M50.5 74.6154L50.5 71.6154" stroke="#4996D1"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Rejected: React.FC = () => (
  <svg width="100" height="101" viewBox="0 0 100 101"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.4167 92.2821C73.4285 92.2821 92.0833 73.6273 92.0833 50.6154C92.0833 27.6035 73.4285 8.94872 50.4167 8.94872C27.4048 8.94872 8.75 27.6035 8.75 50.6154C8.75 73.6273 27.4048 92.2821 50.4167 92.2821Z" stroke="#F79E1B"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M50.5 56.6154L50.5 26.6154" stroke="#F79E1B"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M50.5 74.6154L50.5 71.6154" stroke="#F79E1B"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Success: React.FC<{ bold?: boolean }> = ({ bold }) => (
  <svg width="100" height="101" viewBox="0 0 100 101"
    fill="none" xmlns="http://www.w3.org/2000/svg" className="success-2"
    data-testid="success-icon">
    <path
      d="M91.6667 46.782V50.6153C91.6615 59.6004 88.7521 68.3431 83.3722 75.5396C77.9923 82.736 70.4303 88.0006 61.814 90.5482C53.1976 93.0958 43.9886 92.7899 35.5603 89.6761C27.132 86.5623 19.936 80.8074 15.0456 73.2698C10.1553 65.7321 7.83244 56.8156 8.42363 47.85C9.01482 38.8843 12.4883 30.35 18.3261 23.5198C24.1639 16.6896 32.0533 11.9294 40.8174 9.94926C49.5816 7.96911 58.7511 8.87506 66.9583 12.532"
      stroke="#1BC491" strokeWidth={!!bold ? '10' : '2.5'} strokeLinecap="round"
      strokeLinejoin="round" className="animate-1" />
    <path d="M91.6667 17.2817L50 58.9901L37.5 46.4901" stroke="#1BC491" strokeWidth={!!bold ? '10' : '2.5'}
      strokeLinecap="round" strokeLinejoin="round" className="animate-2" />
  </svg>
);

export const Error: React.FC = () => (
  <svg width="100" height="101" viewBox="0 0 100 101"
    fill="none" xmlns="http://www.w3.org/2000/svg" className="error-2">
    <path d="M50 92.2821C73.0119 92.2821 91.6667 73.6273 91.6667 50.6154C91.6667 27.6035 73.0119 8.94873 50 8.94873C26.9881 8.94873 8.33333 27.6035 8.33333 50.6154C8.33333 73.6273 26.9881 92.2821 50 92.2821Z" stroke="#F23A50"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M62.5 38.1152L37.5 63.1152" stroke="#F23A50"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M37.5 38.1152L62.5 63.1152" stroke="#F23A50"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Progress: React.FC = () => (
  <svg width="86" height="86" viewBox="0 0 86 86"
    fill="none" xmlns="http://www.w3.org/2000/svg" className="inProgress">
    <path d="M84.6666 39.1667V43C84.6615 51.9851 81.752 60.7278 76.3721 67.9243C70.9923 75.1207 63.4303 80.3853 54.8139 82.9329C46.1975 85.4805 36.9885 85.1746 28.5602 82.0608C20.1319 78.9469 12.936 73.1921 8.04559 65.6544C3.1552 58.1168 0.832382 49.2003 1.42357 40.2346C2.01476 31.269 5.48828 22.7347 11.3261 15.9045C17.1639 9.07425 25.0532 4.31409 33.8174 2.33394C42.5816 0.353789 51.751 1.25974 59.9583 4.91667" stroke="#3A9CF2"
      strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CardName: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6668 17.835V16.1683C16.6668 15.2842 16.3156 14.4364 15.6905 13.8113C15.0654 13.1862 14.2176 12.835 13.3335 12.835H6.66683C5.78277 12.835 4.93493 13.1862 4.30981 13.8113C3.68469 14.4364 3.3335 15.2842 3.3335 16.1683V17.835" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.99984 9.50163C11.8408 9.50163 13.3332 8.00924 13.3332 6.16829C13.3332 4.32735 11.8408 2.83496 9.99984 2.83496C8.15889 2.83496 6.6665 4.32735 6.6665 6.16829C6.6665 8.00924 8.15889 9.50163 9.99984 9.50163Z" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CardNumber: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5002 3.66846H2.50016C1.57969 3.66846 0.833496 4.41465 0.833496 5.33512V15.3351C0.833496 16.2556 1.57969 17.0018 2.50016 17.0018H17.5002C18.4206 17.0018 19.1668 16.2556 19.1668 15.3351V5.33512C19.1668 4.41465 18.4206 3.66846 17.5002 3.66846Z" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.833496 8.66846H19.1668" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CardExpiry: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8333 3.66846H4.16667C3.24619 3.66846 2.5 4.41465 2.5 5.33512V17.0018C2.5 17.9223 3.24619 18.6685 4.16667 18.6685H15.8333C16.7538 18.6685 17.5 17.9223 17.5 17.0018V5.33512C17.5 4.41465 16.7538 3.66846 15.8333 3.66846Z" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3335 2.00146V5.3348" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.6665 2.00146V5.3348" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 8.66846H17.5" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CardCvc: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8333 9.50146H4.16667C3.24619 9.50146 2.5 10.2477 2.5 11.1681V17.0015C2.5 17.9219 3.24619 18.6681 4.16667 18.6681H15.8333C16.7538 18.6681 17.5 17.9219 17.5 17.0015V11.1681C17.5 10.2477 16.7538 9.50146 15.8333 9.50146Z" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.8335 9.50146V6.16813C5.8335 5.06306 6.27248 4.00325 7.05388 3.22185C7.83529 2.44045 8.89509 2.00146 10.0002 2.00146C11.1052 2.00146 12.165 2.44045 12.9464 3.22185C13.7278 4.00325 14.1668 5.06306 14.1668 6.16813V9.50146" stroke="#3A9CF2"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Card: React.FC = () => (
  <svg width="45" height="40" viewBox="0 0 45 40"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.6662 7.43115H5.83362C3.81884 7.43115 2.18555 8.87851 2.18555 10.6639V30.0604C2.18555 31.8458 3.81884 33.2932 5.83362 33.2932H38.6662C40.681 33.2932 42.3143 31.8458 42.3143 30.0604V10.6639C42.3143 8.87851 40.681 7.43115 38.6662 7.43115Z" stroke="#5B6775"
      strokeOpacity="0.7" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.18555 17.1294H42.3143" stroke="#5B6775" strokeOpacity="0.7"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const InputError: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_632_3116)">
      <path d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334Z" stroke="#F23A50"
        strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 13.3333V10" stroke="#F23A50" strokeWidth="1.2"
        strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 6.66675H10.0083" stroke="#F23A50" strokeWidth="1.2"
        strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_632_3116">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const OpenBanking: React.FC = () => (
  <svg width="24" height="30" viewBox="0 0 24 30"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 26H2C1.44772 26 1 25.5523 1 25V23C1 22.4477 1.44772 22 2 22H22C22.5523 22 23 22.4477 23 23V25C23 25.5523 22.5523 26 22 26Z" stroke="#5C6876" />
    <path d="M22 12H2C1.44772 12 1 11.5523 1 11V10.2173C1 9.84129 1.21096 9.49703 1.54603 9.32632L11.546 4.2313C11.8312 4.08599 12.1688 4.08599 12.454 4.2313L22.454 9.32632C22.789 9.49703 23 9.84129 23 10.2173V11C23 11.5523 22.5523 12 22 12Z" stroke="#5C6876" />
    <path d="M4 12V22" stroke="#5C6876" />
    <path d="M9 12V22" stroke="#5C6876" />
    <path d="M15 12V22" stroke="#5C6876" />
    <path d="M20 12V22" stroke="#5C6876" />
    <circle cx="12" cy="8" r="1.5"
      stroke="#5C6876" />
  </svg>
);

export const Download: React.FC = () => (
  <svg width="21" height="21" viewBox="0 0 21 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.625 13V16.3333C17.625 16.7754 17.4494 17.1993 17.1368 17.5118C16.8243 17.8244 16.4004 18 15.9583 18H4.29167C3.84964 18 3.42572 17.8244 3.11316 17.5118C2.80059 17.1993 2.625 16.7754 2.625 16.3333V13" stroke="#3A9CF2"
      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.95825 8.8335L10.1249 13.0002L14.2916 8.8335" stroke="#3A9CF2"
      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.125 13V3" stroke="#3A9CF2" strokeWidth="2"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CloseSquare: React.FC = () => (
  <svg width="21" height="21" viewBox="0 0 21 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2083 3H4.54167C3.62119 3 2.875 3.74619 2.875 4.66667V16.3333C2.875 17.2538 3.62119 18 4.54167 18H16.2083C17.1288 18 17.875 17.2538 17.875 16.3333V4.66667C17.875 3.74619 17.1288 3 16.2083 3Z" stroke="#5C6876" strokeLinecap="round"
      strokeLinejoin="round" />
    <path d="M7.875 8L12.875 13" stroke="#5C6876" strokeLinecap="round"
      strokeLinejoin="round" />
    <path d="M12.875 8L7.875 13" stroke="#5C6876" strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
);

export const Close: React.FC = () => (
  <svg width="21" height="21" viewBox="0 0 21 21"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.375 5.5L5.375 15.5" stroke="white" strokeWidth="2"
      strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.375 5.5L15.375 15.5" stroke="white" strokeWidth="2"
      strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
