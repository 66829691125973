import React from 'react';

import { TranslationContextProvider } from './context/TranslationContext';
import { PaymentContextProvider } from './context/PaymentContext';
import { StatusConfigContextProvider } from 'src/context/StatusConfigContext';
import { StyleContextProvider } from './context/StyleContext';
import { FieldsContextProvider } from './context/FieldsContext';
import { ThreeDsContextProvider } from './context/ThreeDsContext';
import { VisitorContextProvider } from './context/VisitorContext';
import { InstalmentContextProvider } from './context/InstalmentContext';

export const Contexts: React.FC<React.PropsWithChildren> = ({ children }) => (
  <VisitorContextProvider>
    <TranslationContextProvider>
      <PaymentContextProvider>
        <StatusConfigContextProvider>
          <StyleContextProvider>
            <FieldsContextProvider>
              <ThreeDsContextProvider>
                <InstalmentContextProvider>
                  {children}
                </InstalmentContextProvider>
              </ThreeDsContextProvider>
            </FieldsContextProvider>
          </StyleContextProvider>
        </StatusConfigContextProvider>
      </PaymentContextProvider>
    </TranslationContextProvider>
  </VisitorContextProvider>
);
