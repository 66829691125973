import { Values } from './useCardForm';

const formatNumbers = (v: string) => v.replace(/\D/g, '');

const formatCardId = (val: string) => {
  let newValue = '';
  val = formatNumbers(val);
  for (let i = 0; i < val.length; i++) {
    if (i % 4 === 0 && i > 0) { newValue = newValue.concat(' '); }
    newValue = newValue.concat(val[i]);
  }
  return newValue;
};

const formatCardCvc = formatNumbers;

const formatMonth = (val: string) => {
  val = formatNumbers(String(val));
  const numVal = Number(val);

  if (numVal === 1) {
    return val;
  }

  if (numVal > 1 && numVal < 10) {
    return `0${numVal}`;
  }

  if (numVal > 19) {
    return String(19);
  }

  return val;
};

export const useVisibleValues = (values: Values) => ({
  cardholder_name: String(values?.cardholder_name),
  // eslint-disable-next-line id-denylist
  number: formatCardId(values?.number || ''),
  exp_month: formatMonth(values?.exp_month || ''),
  exp_year: formatNumbers(String(values?.exp_year)),
  csc: formatCardCvc(values?.csc || ''),
  save_card: !values?.save_card,
  instalment_plan: values?.instalment_plan,
  instalment_agree_to_toc: values?.instalment_agree_to_toc,
});
