import React from 'react';

import { useContextDetails } from 'src/hooks/useContextDetails';
import { isIframe } from 'src/utils/iframe';
import { sdk } from 'src/sdk';

interface UrlProps {
  url: string;
  newTab?: boolean;
  isApm?: boolean;
}

export const openUrl = ({
  url,
  newTab = false,
  isApm = false,
}: UrlProps) => {

  if (isIframe && sdk.hasOrigin) {
    if (isApm) {
      sdk.apmRedirectUrl = url;
    }
    if (newTab) {
      sdk.newWindowUrl = url;
    }
    return;
  }

  if (newTab) {
    window.open(url, '_blank', 'noopener,noreferrer');
    return;
  }

  window.location.href = url;
};

export const CancelLink: React.FC = () => {
  const { paymentDetails, t } = useContextDetails();

  const hasCancelLink = typeof paymentDetails.cancel_redirect === 'string';
  const cancelUrl = String(paymentDetails.cancel_redirect);

  if (!(hasCancelLink && cancelUrl.length > 0)) {
    return <></>;
  }

  const onCancelClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!hasCancelLink) {
      return;
    }

    return openUrl({ url: cancelUrl, isApm: true });
  };

  return (
    <div className="cancelRedirect" data-testid="cancelRedirect">
      <a
        href="#"
        data-testid="cancelLink"
        onClick={onCancelClick}
      >
        {t.CancelPayment}
      </a>
    </div>
  );
};

