import { useContext, useState } from 'react';

import {
  DEMO_AMOUNT,
  DEMO_BRAND,
  DEMO_ID,
  DEMO_CURRENCY,
} from 'src/demo/constants';
import { GATE_API_HOST } from 'src/constants/environment';
import {
  paymentInitialState,
  Payment,
  PaymentResponse,
  PaymentMethods,
} from 'src/hooks/usePayment';
import { setTranslation } from 'src/components/payment/LanguageSwitcher';
import { TranslationContext } from 'src/context/TranslationContext';
import { useOnce } from './useOnce';
import { language, SUPPORTED_LANGUAGES } from 'src/languages/language';

export const DEMO_LOADING_TIME = 1000;

export const paymentFactory = (props?: object) => ({ ...paymentInitialState, ...(props || {}) });

// The order of currencies is important !!!
const CURRENCIES: { [key: string]: string } = {
  'A$': 'AUD', // Australian dollar
  '$': 'USD', // United States Dollar
  '€': 'EUR', // Euro
  '¥': 'JPY', // Japanese Yen
  '£': 'GBP', // British Pound
  '₩': 'KRW', // South Korean Won
  '₺': 'TRY', // Turkish Lira
  '₽': 'RUB', // Russian Ruble
  '₹': 'INR', // Indian Rupee
  'zł': 'PLN', // Polish zlot
};

const CURRENCY_CODES = Object.keys(CURRENCIES);

export const fixCurrencyCodes = (amount: string): string => {
  let response = amount;
  CURRENCY_CODES.map(char => {
    response = response.replace(char, CURRENCIES[char]);
  });
  return response;
};

const formatDate = () =>
  new Date().toLocaleDateString('en-US', { year: '2-digit', month: 'numeric', day: 'numeric' });

export const useDemoPayment = (): PaymentResponse => {
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState<Payment>(paymentInitialState);

  const translationContext = useContext(TranslationContext);

  const paymentObject = paymentFactory({
    'hash': DEMO_ID,
    'pay_form_config_url': 'random',
    'amount': `${DEMO_CURRENCY} ${DEMO_AMOUNT}`,
    'amount_digits': DEMO_AMOUNT,
    'currency': DEMO_CURRENCY,
    'brand_name': DEMO_BRAND,
    'is_test': true,
    'issued_on': formatDate(),
    'branding_gate_link': GATE_API_HOST,
    'recurring': true,
    'demo_payment': true,
    'lang': SUPPORTED_LANGUAGES.EN,
    'cardholder_name_validator': '',
    'cancel_redirect': '',
    'accepted_payment_methods': {
      '0': PaymentMethods.CARD,
    },
    'accepted_cards': ['VISA', 'MasterCard'],
    'saved_card': {},
  });

  useOnce(() => setTimeout(() => {
    setTranslation(paymentObject.lang_code, translationContext);
    language.toStorage = language.getLanguage(paymentObject.lang_code as SUPPORTED_LANGUAGES);
    setPayment(paymentObject);
    setLoading(false);
  }, DEMO_LOADING_TIME));

  return {
    payment,
    loading,
    error: '',
  };
};
