import React from 'react';
import styled from 'styled-components';

import { VisaLogo, VISA_COLOR } from 'src/assets/Logos';
import { openUrl } from 'src/components/payment/CancelLink';
import { makeTextNonSelectable } from './Eligibility';
import { useContextDetails } from 'src/hooks/useContextDetails';

interface Props {
  url: string;
}

const FooterContainer = styled.footer`
  display: flex;
  font-size: small;
  font-weight: 500;
  width: 100%;
  margin-top: -10px;
  justify-content: space-between;
  color: ${VISA_COLOR};
  
  div {
    display: flex;

    &:first-child {
      line-height: 17px;
      align-items: center;
      svg {
        width: 30px;
        height: 11px;
      }
    }

    &:last-child {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const TextContainer = styled.div`
  ${makeTextNonSelectable}
`;

export const Footer: React.FC<Props> = ({ url }) => {
  const { t } = useContextDetails();
  return (
    <FooterContainer>
      <TextContainer>
        {t.InstalmentsEnabledBy} <VisaLogo />
      </TextContainer>
      <div>
        <span onClick={() => openUrl({ url, newTab: true })}>
          {t.LearnMore}
        </span>
      </div>
    </FooterContainer>
  );
};
