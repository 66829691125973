import React from 'react';
import styled from 'styled-components';

import { CardFormFields, FieldValue } from 'src/hooks/useCardForm';
import { openUrl } from 'src/components/payment/CancelLink';
import { TocInfoLink } from 'src/components/instalments/TocInfoLink';
import { useContextDetails } from 'src/hooks/useContextDetails';

interface Props {
  text: string;
  url: string;
  hasError: boolean;
  onChange(field: CardFormFields, value: FieldValue): void;
}

const Container = styled.label<{ $hasError: boolean }>`
  position: relative;
  width: 100%;
  margin: 0 0 15px;
  font-size: 17px;
  color: var(--pf2-textColor);
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid var(${({ $hasError }) => $hasError ? '--pf2-failureColor' : '--pf2-borderColor'});
    transition: 0.2s;
    border-radius: 3px;
  }
`;

const Checkbox = styled.input`
  display: none;

  &:not(checked) {
    ~ span {
      &:before {
        top: 6px;
        left: 3px;
        opacity: 0;
      }
      &:after {
        top: 3px;
        opacity: 0;
      }
    }
  }
  
  &:checked {
    ~ span {
      &:before {
        top: 6px;
        left: 3px;
        opacity: 1;
      }
      &:after {
        top: 3px;
        opacity: 1;
      }
    }
  }

  &:before {
    content: ""
    position: absolute;
    top: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid var(--pf2-borderColor);
    background: green;
    transition: 0.2s;
    border-radius: 4px;
    left: 0;
    margin-right: 10px;
  }

  &:hover:before {
    border-color: var(--pf-primaryColor);
  }

  &:last-child {
    margin: 0 0 30px;
  }
`;

const ToC = styled.span`
  font-size: small !important;
  margin-bottom: 5px;
  display: inline;

  position: relative;
  padding-left: 20px;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    background: var(--pf-primaryColor);
    opacity: 0;
    transition: 0.2s;
  }

  &:before {
    width: 2px;
    height: 6px;
    transform: rotate(-40deg);
    left: 5px;
  }

  &:after {
    width: 2px;
    height: 10px;
    transform: rotate(40deg);
    left: 8px;
  }

  & h2, & h3, & p, & span, & p strong, & p em {
    font-weight: normal !important;
    text-decoration: none !important;
    display: inline;
    font-style: normal !important;
    font-size: small !important;
    color: var(--pf2-textColor) !important;
  }

  & p a {
    color: var(--pf-buttonHoverColor);
    border-bottom: 1px dotted var(--pf-buttonHoverColor) !important;
  }
`;

const CHECKBOX_MAX_Y = 15;
const CHECKBOX_MAX_X = 15;

export const TocCheckbox: React.FC<Props> = ({
  text,
  url,
  hasError,
  onChange,
}) => {
  const { t } = useContextDetails();
  const fieldName = CardFormFields.INSTALMENT_AGREE_TO_TOC;
  const tocRef = React.useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Do not put preventDefault here!!!
    const target = event.target;
    onChange(fieldName, target.checked);
  };

  // allow only upper left corner to be clicked
  const handleToCClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const tagName = target.tagName.toLowerCase();
    if (tagName === 'a') {
      const linkUrl = target.getAttribute('href');
      if (!!linkUrl) {
        event.stopPropagation();
        event.preventDefault();
        return openUrl({ url: linkUrl, newTab: true });
      }
    }

    const element = tocRef.current;
    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();

    const relativeX = event.clientX - rect.left;
    const relativeY = event.clientY - rect.top;

    if (relativeX > CHECKBOX_MAX_X || relativeY > CHECKBOX_MAX_Y) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <Container
      $hasError={hasError}
      htmlFor={fieldName}
      data-testid="rememberCheckboxLabel"
    >
      <Checkbox
        type="checkbox"
        data-testid="agreeToToCCheckbox"
        id={fieldName}
        name={fieldName}
        onChange={handleChange}
      />
      <ToC
        ref={tocRef}
        data-testid="rememberCheckboxContent"
        onClick={handleToCClick}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {!!url && (
        <TocInfoLink url={url}>
          {t.FurtherInformation}
        </TocInfoLink>
      )}
    </Container>
  );
};
