import { isDemoCardId } from 'src/demo/constants';
import { useContextDetails } from 'src/hooks/useContextDetails';
import { hasSavedCard, usePaymentFields } from 'src/hooks/usePaymentFields';
import { isValidCardHolderName, luhnCheck } from 'src/utils/validators';
import { useCardType } from 'src/hooks/useCardType';
import { i18n } from 'src/languages';
import { dropSpaces } from 'src/utils/string';
import { INSTALMENT_WITHOUT_VALUE_HASH } from 'src/components/instalments/PlanInput';
import { InstalmentValueType } from './useInstalments';
import { isObject } from 'src/backend/client';
import { CardFormFields, Values } from './useCardForm';

export type Errors = {
  [key in CardFormFields]: string;
}

type FieldName = keyof Values;
export type FieldValue = string | boolean | undefined | InstalmentValueType;
type ValidatorType = (value: FieldValue) => string | undefined;
type ErrorsType = { [key in FieldName]: string };

interface Props {
  values: Values;
  visibleValues: Values,
  dirtyFields: Array<keyof Values>;
  newCardMode: boolean;
  addonsEnabled: boolean;
  isSubmitted: boolean;
}

interface Response {
  errors: Errors;
  isValidForm: boolean;
}

export const MONTH_MAX = 12;
export const MONTH_MIN = 0;
const NO_ERROR = '';

const getDate = () => {
  const date = new Date();
  return {
    currentYear: date.getFullYear().toString().slice(-2),
    currentMonth: date.getMonth() + 1,
  };
};

export const useValidateFields = ({
  values: {
    // eslint-disable-next-line
    number,
    exp_year,
    exp_month,
    instalment_plan,
  },
  values,
  visibleValues,
  dirtyFields,
  newCardMode,
  addonsEnabled,
  isSubmitted,
}: Props): Response => {
  const { t, paymentDetails } = useContextDetails();
  const {
    showCvc,
  } = usePaymentFields({ newCardMode });
  const { cardType, cvcMaxLength, isVisaCard } = useCardType(String(number));
  const isSavedCardPayment = hasSavedCard(paymentDetails) && !newCardMode;

  const isDirty = (name: FieldName) => (dirtyFields || []).includes(name);

  const shouldValidateField = (name: FieldName) => {
    const AUTO_VALIDATE_FIELDS = [
      CardFormFields.CARDHOLDER_NAME,
      CardFormFields.INSTALMENT_PLAN,
    ];
    return AUTO_VALIDATE_FIELDS.includes(name as CardFormFields)
      ? isSubmitted || isDirty(name)
      : isSubmitted;
  };

  const validate = (name: FieldName, value: FieldValue, validator: ValidatorType) =>
    shouldValidateField(name) ? (validator(value) || NO_ERROR) : NO_ERROR;

  const validateCardHolderName = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }

    if (!isValidCardHolderName(String(value))) {
      return i18n(t.ProvideName);
    }
  };

  const validateCardNumber = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }
    const cleanedValue = dropSpaces(String(value));


    const acceptedCards = paymentDetails.accepted_cards;
    const lowerAcceptedCards = acceptedCards.map((element) => element.toLowerCase());

    if (!lowerAcceptedCards.includes(String(cardType))) {
      return i18n(t.ProvideValidNumber);
    }

    if (!(cleanedValue.length && (luhnCheck(cleanedValue) || isDemoCardId(cleanedValue)))) {
      return i18n(t.ProvideNumber);
    }
  };

  const validateExpMonth = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }
    const date = getDate();
    const i18nValue = i18n(t.ValidDate);
    if (!value) {
      return i18nValue;
    }
    if (Number(value) > MONTH_MAX || Number(value) < MONTH_MIN) {
      return i18nValue;
    }
    if (exp_year === date.currentYear && Number(value) < Number(date.currentMonth)) {
      return i18nValue;
    }
  };

  const validateExpYear = (value: FieldValue) => {
    if (isSavedCardPayment) {
      return;
    }
    const i18nValue = i18n(t.ValidDate);
    if (!value) {
      return i18nValue;
    }
    const date = getDate();
    if (Number(value) < Number(date.currentYear)) {
      return i18nValue;
    }
    if (Number(value) === Number(date.currentYear) && Number(exp_month) < Number(date.currentMonth)) {
      return i18nValue;
    }
  };

  const validateCsc = (value: FieldValue) => {
    if (showCvc && !paymentDetails.cvv_optional) {
      const length = String(value).length;
      if (!length || length < cvcMaxLength) {
        return i18n(t.ProvideCVC);
      }
    }
  };

  const validateInstalmentPlan = (value: FieldValue): string | undefined => {
    if (!addonsEnabled) {
      return;
    }
    if (!isVisaCard) {
      return;
    }
    if (typeof value === 'string') {
      if (!value) {
        return i18n(t.PleaseSelectPlan);
      }
    }
    if (isObject(value)) {
      return validateInstalmentPlan((value as InstalmentValueType).id);
    }
  };

  const validateInstalmentAgreeToC = (value: FieldValue) => {
    if (!addonsEnabled) {
      return;
    }
    if (!isVisaCard) {
      return;
    }
    if (instalment_plan === INSTALMENT_WITHOUT_VALUE_HASH) {
      return;
    }
    if (!value) {
      return i18n(t.YouNeedAgreeToC);
    }
  };

  const dummyValidator = (value: FieldValue) => value as string;

  const validators: { [key in FieldName]: ValidatorType } = {
    cardholder_name: validateCardHolderName,
    // eslint-disable-next-line id-denylist
    number: validateCardNumber,
    exp_month: validateExpMonth,
    exp_year: validateExpYear,
    csc: validateCsc,
    instalment_plan: validateInstalmentPlan,
    instalment_agree_to_toc: validateInstalmentAgreeToC,
    save_card: dummyValidator,
  };

  const errors: ErrorsType = Object
    .keys(values || {})
    .reduce((accumulated, current) => ({
      ...accumulated,
      [current]: validate(
        current as FieldName,
        visibleValues[current as FieldName],
        validators[current as FieldName],
      ),
    }), {} as ErrorsType);

  const isValidForm = !Object.values(errors).filter((error: string) => !!error.length).length;

  return {
    errors,
    isValidForm,
  };
};
