import React from 'react';
import styled from 'styled-components';

import { openUrl } from 'src/components/payment/CancelLink';

const Container = styled.p`
  font-size: small !important;
  margin: 5px 0 0 !important;

  & > a {
    color: var(--pf-buttonHoverColor);
    border-bottom: 1px dotted var(--pf-buttonHoverColor) !important;
  }
`;

interface Props extends React.PropsWithChildren {
  url: string;
}

export const TocInfoLink: React.FC<Props> = ({ url, children }) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    openUrl({ url, newTab: true });
  };

  return (
    <Container>
      <a href={url} onClick={handleClick}>
        {children}
      </a>
    </Container>
  );
};
