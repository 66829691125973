import React from 'react';
import styled from 'styled-components';

import { ErrorInfo } from 'src/components/inputs/ErrorInfo';

interface Props extends React.PropsWithChildren {
  error: string;
}

const HeaderBlock = styled.div`
  display: inline !important;
`;

const HeaderContainer = styled.h3<{ $hasError: boolean }>`
  font-size: 23px;
  font-weight: 400;
  margin: 8px 0 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;

  @media (min-width: 1025px) {
    .error-icon {
      right: 0;
      top: 0;
      width: 20px !important;
      height: 34px !important;

      &:after {
        top: 0 !important;
      }
    }

    .error-info {
      bottom: 30px !important;
    }
  }

  @media (max-width: 1024px) {
    padding-right: 40px;
    .error-icon {
      right: 0;
      top: 0;
      width: 20px !important;
      height: 34px !important;

      &:after {
        top: -2px !important;
      }
    }

    .error-info {
      top: -39px !important;
      bottom: unset !important;
    }
  }

  ${({ $hasError }) => $hasError && `
    color: var(--pf2-failureColor) !important;
  `}
`;

export const Header: React.FC<Props> = ({
  error,
  children,
}) => (
  <HeaderBlock className="formTitle" data-testid="instalmentTitle">
    <div className="inputWrapper">
      <HeaderContainer
        data-testid="instalmentHeading"
        $hasError={!!error}
      >
        {children}
        {!!error && (<ErrorInfo id="instalmentError">{error}</ErrorInfo>)}
      </HeaderContainer>
    </div>
  </HeaderBlock>
);
