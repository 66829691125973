import React from 'react';
import { styled } from 'styled-components';

import { Success } from 'src/assets/Icons';

export const makeTextNonSelectable = `
  cursor: default;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const InstalmentEligibilityContainer = styled.div`
  width: 100%;
  font-size: small;
  display: flex;
  margin: -8px 0 16px;

  ${makeTextNonSelectable}

  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  &>svg {
    margin-left: 4px;
    width: 18px;
    height: 18px;
  }
`;

interface Props extends React.PropsWithChildren {
  success?: boolean;
}

export const Eligibility: React.FC<Props> = ({ success, children }) => (
  <InstalmentEligibilityContainer>
    {children}
    {success && (<Success data-test-id="success-icon" bold />)}
  </InstalmentEligibilityContainer>
);
