import React from 'react';
import { InputError } from 'src/assets/Icons';

interface Props {
  id?: string;
  position?: string;
  children: React.ReactNode;
}

export const ErrorInfo: React.FC<Props> = ({ position, children, id }) => (
  <>
    <span className="error-icon" data-testid="errorIcon">
      <InputError />
    </span>
    <div
      className={`error-info ${position || ''}`}
      data-testid="nameError"
      id={id}
    >
      <p>{children}</p>
    </div>
  </>
);
