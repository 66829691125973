import React from 'react';
import styled from 'styled-components';

import {
  InstalmentConfigType,
  InstalmentPlanType,
  InstalmentTocType,
} from 'src/hooks/useInstalments';
import { useContextDetails } from 'src/hooks/useContextDetails';
import { CardFormFields, FieldValue } from 'src/hooks/useCardForm';
import { Plan } from 'src/components/instalments/Plan';
import { Footer } from 'src/components/instalments/Footer';
import { Header } from 'src/components/instalments/Header';
import { fixCurrencyCodes } from 'src/hooks/useDemoPayment';
import { SUPPORTED_LANGUAGES } from 'src/languages/language';

interface Props {
  config: InstalmentConfigType;
  error: string;
  value: string;
  tocError: string;
  validationId: number;
  disabled: boolean;
  onChange(field: CardFormFields, value: FieldValue): void;
}

const FREQUENCIES = {
  MONTHLY: 'month',
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  &>svg {
    margin-left: 10px;
  }
`;

export const INSTALMENT_WITHOUT_VALUE_HASH = 'd1f26af1-c861-4297-80a7-a1efdc9f5cb3';
const INSTALMENT_FOOTER_CLICK_URL = 'https://www.visa.co.uk/how-you-pay-matters/pay-instalments.html';

const decodeEncodedHTML = (encodedHtml: string) => {
  const textarea = document.createElement('textarea');
  const unescapedHtml = encodedHtml.replace(/\\"/g, '"');
  textarea.innerHTML = unescapedHtml;
  const decoded = textarea.value;
  textarea.remove();
  return decoded;
};

const tocByLanguage = (language: string, tocItems: InstalmentTocType[]): InstalmentTocType | undefined => {
  const tocMap = new Map(tocItems.map(item => [item.language, item]));
  return tocMap.get(language) || tocMap.get(SUPPORTED_LANGUAGES.EN) || tocItems[0];
};

export const PlanInput: React.FC<Props> = ({
  config,
  value,
  error,
  tocError,
  validationId,
  disabled,
  onChange,
}) => {
  const { paymentDetails, t } = useContextDetails();
  const currency = fixCurrencyCodes(paymentDetails.currency);
  const language = paymentDetails.lang_code;
  const onStateChange = disabled ? () => { } : onChange;

  const defaultAmount = `${currency} ${paymentDetails.amount_digits}`;
  const renderEmptyPlan = () => (
    <Plan
      header={t.PayNow}
      validationId={validationId}
      name={CardFormFields.INSTALMENT_PLAN}
      value={INSTALMENT_WITHOUT_VALUE_HASH}
      id={INSTALMENT_WITHOUT_VALUE_HASH}
      error={tocError}
      onChange={onStateChange}
      checked={value === INSTALMENT_WITHOUT_VALUE_HASH}
      paymentAmount={`${fixCurrencyCodes(defaultAmount)}`}
      empty
    />
  );

  const getPlanProps = (plan: InstalmentPlanType) => {
    const {
      amount_digits: amountDigits,
      fee_digits: feeDigits,
      total_fee_digits: totalFee,
      total_amount_digits: totalAmount,
      terms_conditions: termsConditions,
      annual_percentage_rate: annualPercentageRate,
      number_of_instalments: numberOfInstalments,
      frequency,
      id,
    } = plan;
    const tocDetails = tocByLanguage(language, termsConditions);

    const frequencyTitle = FREQUENCIES[frequency];
    const checked = value === id;
    const header = `${t.PayIn as string} ${numberOfInstalments}`;
    const paymentAmount = `${currency} ${amountDigits}/${frequencyTitle}`;
    const apr = `${t.APR as string}: ${annualPercentageRate}%`;
    const feeBlock = `${t.Fee as string}: ${currency} ${feeDigits}/${frequencyTitle} (${apr})`;
    const includingFee = `${t.Including as string} ${currency} ${totalFee} ${String(t.Fee).toLowerCase()}`;
    const total = `${t.Total as string}: ${currency} ${totalAmount} (${includingFee})`;
    const toc = !!tocDetails ? decodeEncodedHTML(tocDetails.text) : '';
    const url = !!tocDetails ? tocDetails.url : '';

    return {
      value: id,
      title: header,
      checked,
      header,
      paymentAmount,
      fee: feeBlock,
      total,
      toc,
      url,
    };
  };

  const renderPlan = (
    plan: InstalmentPlanType,
    index: number,
  ) => (
    <Plan
      {...getPlanProps(plan)}
      name={CardFormFields.INSTALMENT_PLAN}
      validationId={validationId}
      id={`plan-${index}`}
      key={`pr-${index}`}
      error={tocError}
      onChange={onStateChange}
    />
  );

  return (
    <Container>
      <Header error={error}>
        {t.PayNowOrSelectPlan}
      </Header>
      {renderEmptyPlan()}
      {config.instalment_plans.map(renderPlan)}
      <Footer url={INSTALMENT_FOOTER_CLICK_URL} />
    </Container>
  );
};
