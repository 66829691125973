import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { VisaInstalmentsSymbol } from 'src/assets/Logos';
import { CardFormFields, FieldValue } from 'src/hooks/useCardForm';
import { TocCheckbox } from 'src/components/instalments/TocCheckbox';
import { makeTextNonSelectable } from './Eligibility';

type inputAttrs = InputHTMLAttributes<HTMLInputElement>;
interface Props extends Omit<inputAttrs, 'type' | 'onChange' | 'error'> {
  header: React.ReactNode;
  paymentAmount: React.ReactNode;
  fee?: React.ReactNode;
  total?: React.ReactNode;
  wrapperClassName?: string;
  error: string;
  validationId: number;
  toc?: string;
  url?: string;
  empty?: boolean;
  onChange(field: CardFormFields, value: FieldValue): void;
}

interface PlanContainerProps {
  $isFocused: boolean;
  $hasError: boolean;
}

const PlanContainer = styled.div<PlanContainerProps>`
  z-index: 200;
  position: relative;
  border: 1px solid var(--pf2-borderColor);
  padding: 0;
  border-radius: 6px;
  transition: 0.2s;
  opacity: 0.85;

  margin: 0 0 16px;
  display: flex;

  ${({ $isFocused }) => !$isFocused && (`
    * {
      cursor: pointer !important;
    }
  `)}

  ${({ $isFocused }) => $isFocused && (`
    opacity: 1;
    box-shadow: 0 0 15px rgb(0, 0, 0, 0.1);
    &>path {
      stroke: var(--pf-primaryColor);
    }
    `
  )}

  ${({ $isFocused, $hasError }) => {
    if ($hasError) {
      return ('border-color: var(--pf2-failureColor);');
    } else if ($isFocused) {
      return ('border-color: var(--pf-primaryColor);');
    } else {
      return ('border-color: var(--pf2-borderColor);');
    }
  }}
`;

const Icon = styled.span<PlanContainerProps>`
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 15px;
  height: 15px;

  &>svg {
    width: 100%;
    height: 100%;

  ${({ $isFocused, $hasError }) => {
    if ($hasError) {
      return (`
        path {
          fill: var(--pf2-failureColor);
        }

        rect {
          fill: var(--pf2-failureColor);
        }
      `);
    } else if ($isFocused) {
      return (`
        path {
          fill: var(--pf-primaryColor);
        }

        rect {
          fill: var(--pf-primaryColor);
        }
      `);
    } else {
      return (`
      path {
        fill: var(--pf2-borderColor);
      }

      rect {
        fill: var(--pf2-borderColor);
      }
    `);
    }
  }}
`;

const Input = styled.input`
  z-index: 201;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const Header = styled.h4`
  font-weight: 200;
  font-size: 17px;
  margin: 0;
  color: var(--pf2-headingColor);
  ${makeTextNonSelectable}
`;

const Amount = styled.p`
  font-size: 17px !important;
  margin: 0 !important;
  ${makeTextNonSelectable}
`;

const Meta = styled.p`
  font-size: small !important;
  margin: 0 !important;
  ${makeTextNonSelectable}
`;

const Container = styled.label`
  padding: 5px 10px;
  width: 100%;
`;

export const Plan = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    header,
    paymentAmount,
    fee,
    total,
    id,
    error,
    onChange,
    validationId,
    toc,
    url,
    empty = false,
    ...rest
  } = props;

  const initialValidationId = React.useRef(validationId);
  const showValidationErrors = initialValidationId.current !== validationId;
  const planName = CardFormFields.INSTALMENT_PLAN;
  const inputId = `${planName}-radio-${id || ''}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Do not put preventDefault here!!!
    const value = event.target.value;
    onChange(CardFormFields.INSTALMENT_AGREE_TO_TOC, false);
    onChange(planName, value);
  };

  const hasError = showValidationErrors && !!error;
  const showErrorBlock = !!props.checked && hasError;

  return (
    <PlanContainer
      $isFocused={!!props.checked}
      $hasError={showErrorBlock}
    >
      <Input
        {...rest}
        ref={ref}
        type="radio"
        name={CardFormFields.INSTALMENT_PLAN}
        id={inputId}
        onChange={handleChange}
      />
      <Container htmlFor={inputId}>
        {!empty && (
          <Icon
            $isFocused={!!props.checked}
            $hasError={showErrorBlock}
          >
            <VisaInstalmentsSymbol />
          </Icon>
        )}
        <Header>{header}</Header>
        <Amount>{paymentAmount}</Amount>
        {!!fee && (<Meta>{fee}</Meta>)}
        {!!total && (<Meta>{total}</Meta>)}
        {props.checked && !empty && (
          <TocCheckbox
            onChange={onChange}
            url={url || ''}
            text={toc || ''}
            hasError={hasError}
          />
        )}
      </Container>
    </PlanContainer>
  );
});

